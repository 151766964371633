import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { 
    EVENT_PAID_STATUS_NOT_PAID, 
    EVENT_PAID_STATUS_CREDIT,
    EVENT_DONE_STATUS_CANCELED,
    EVENT_PAID_STATUS_PAID,
    EVENT_DONE_STATUS_DONE,
} from 'Consts/events';
import {
    API_RESOURCE_LOCATION, 
    API_RESOURCE_PRODUCT,
} from 'Consts/apiResources';

import { getFullName } from 'Utils/user';
import { getPromoCodeValue } from 'Utils/promoCode';
import { getPeriodTypeLabel, getStatusLabel } from 'Utils/userOffer';
import { formatPrice } from 'Utils/math';
import { getTitle, getPaidStatusLabel, getDoneStatusLabel } from 'Utils/event';
import { parseQueryToObject } from 'Utils/querystring';
import { getLocationByReservationSlug as getLocation } from 'Utils/location';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Spinner from 'Components/layout/Spinner';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

class PublicReservationConfirmation extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                slug: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            listEvents: PropTypes.func.isRequired,
            singleUserOffer: PropTypes.func.isRequired,
            getLocationBySlug: PropTypes.func.isRequired,
            cancelEvent: PropTypes.func.isRequired,
            singleProduct: PropTypes.func.isRequired,
        }).isRequired,
        inputStyle: PropTypes.string,
        onFormTypeChange: PropTypes.func,
        leadMedium: PropTypes.string,
        leadSource: PropTypes.string,
        events: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.object,
        }),
        userOffer: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };

    state = {
        selectedLocation: {},
        selectedProduct: {},
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        this.loadEvents();
        this.loadUserOffer();
        this.loadLocation();
        this.loadProduct();
    }

    loadEvents = () => {
        const { actions, match } = this.props;

        actions.listEvents({ userOfferId: match.params.id });
    }

    loadUserOffer = () => {
        const { actions, match } = this.props;

        actions.singleUserOffer({ id: match.params.id });
    }

    loadLocation = () => {
        const { actions, match } = this.props;
        
        actions.getLocationBySlug({ slug: getLocation(match.params.slug).locationSlug }).then(response => {
            const selectedLocation = response.payload[API_RESOURCE_LOCATION];

            this.setState({
                selectedLocation
            });
        });
    }

    loadProduct = () => {
        const { actions, location } = this.props;
        const { search } = location;

        const queryObject = parseQueryToObject(search, true);
        actions.singleProduct({ id: queryObject.productId }).then(response => {
            const selectedProduct = response.payload[API_RESOURCE_PRODUCT];

            this.setState({
                selectedProduct
            });
        })
    }

    render() {
        const { events, userOffer, location, history, actions } = this.props;
        const { selectedLocation, selectedProduct } = this.state;
    
        if ((!userOffer && !events) || ((userOffer && userOffer.isLoading) && (events && events.isLoading))) {
            return <Spinner />;
        }

        return (
            <StyledComponent
                styles={require('./styles')}
                className="public-reservation-confirmation"
            >
                <LayoutContainer>
                    <div className="summary-wrapper">
                        <div className="col-left">
                            <div className="header-info">
                                {selectedLocation && (
                                    <div className="location-headline">
                                        {selectedLocation.name}
                                    </div>
                                )}
                                {selectedProduct && (
                                    <h3 className="product-headline">
                                        {selectedProduct.name}
                                    </h3>
                                )}
                            </div>
                            <h1 className="headline">
                                Podsumowanie zamówienia
                            </h1>
                            <h2 className="subheadline">
                                <a className="site-url">{process.env.APP_URL + location.pathname + location.search}</a>
                            </h2>
                            <div className="summary">
                                <div className="summary-user-offer">
                                    <div className="summary-user-offer-info">
                                        <p className="info-headline">Nazwa zamówienia</p>
                                        <p className="info">{userOffer?.data?.name}</p>
                                        <p className="info-headline">Adept</p>
                                        <p className="info">{getFullName(userOffer?.data?.user).label}</p>
                                        <p className="info-headline">Cykliczność</p>
                                        <p className="info">{getPeriodTypeLabel(userOffer?.data).label}</p>
                                        <p className="info-headline">Kod promocyjny</p>
                                        <p className="info">{getPromoCodeValue(userOffer?.data?.promoCode)}</p>
                                        <p className="info-headline">Status</p>
                                        <p className="info">{getStatusLabel(userOffer?.data).label}</p>
                                        <p className="info-headline">Cena</p>
                                        <p className="info">{formatPrice(userOffer?.data?.totalPriceGross)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-right">
                            <div className="headline">Twój trener</div>
                            <div className="summary-trainer-info">
                                <div className="trainer-avatar-wrapper">
                                    <img 
                                        className="trainer-avatar"
                                        src={userOffer?.data?.lead?.avatar}    
                                    />
                                </div>
                                <p className="trainer-name">
                                    {getFullName(userOffer?.data?.lead, 'nameSurname').label}
                                </p>
                                <p className="trainer-mail">{userOffer?.data?.lead?.email}</p>
                                <p className="trainer-bio">
                                    {userOffer?.data?.lead?.bio}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="summary-events-list">
                        <PaginatedList
                            location={location}
                            history={history}
                            collection={events}
                            title={'Wydarzenia z tego zamówienia'}
                            onMapElement={element => (
                                <PaginatedListElement
                                    key={element.id}
                                    title={getTitle(element)}
                                    controls={[{
                                        type: 'button',
                                        label: 'Odwołaj wydarzenie',
                                        visible: Boolean(element.canCancel),
                                        onClick: () => actions.cancelEvent({ id: element.id }).then(() => {
                                            toast('Wydarzenie zostało odwołane');
                                        }),
                                    }]}
                                    subtitle={element.product && element.product.name}
                                    description=''
                                    labels={[{
                                        isVisible: element.paidStatus === EVENT_PAID_STATUS_PAID,
                                        label: element.userOffer.productCategory?.slug === 'masaz' 
                                            ? `Wydarzenie: ${element.order || '-'}/${element.totalCount}`
                                            : `Trening: ${element.order || '-'}/${element.totalCount}`,
                                    }]}
                                    additionals={[{
                                        name: 'Lokalizacja',
                                        iconSrc: require('Theme/images/icons/location.svg'),
                                        value: element.location && element.location.name || 'Brak',
                                    }, {
                                        name: element.userOffer.productCategory?.slug ==='masaz' 
                                            ? 'Twój terapeuta' 
                                            : 'Twój trener',
                                        imageSrc: require('Theme/images/placeholders/image.jpg'),
                                        value: element.lead && `${element.lead?.name} ${element.lead?.surname}`  || 'Brak',
                                    }, {
                                        name: 'Status treningu',
                                        value: [{
                                            isVisible: getPaidStatusLabel(element).key === EVENT_PAID_STATUS_NOT_PAID || getPaidStatusLabel(element).key === EVENT_PAID_STATUS_CREDIT,
                                            label: getPaidStatusLabel(element).label,
                                            state: getPaidStatusLabel(element).stateColor,
                                        }, {
                                            isVisible: getDoneStatusLabel(element).key === EVENT_DONE_STATUS_CANCELED || getDoneStatusLabel(element).key === EVENT_DONE_STATUS_DONE,
                                            label: getDoneStatusLabel(element).label,
                                            state: getDoneStatusLabel(element).stateColor,
                                        }],
                                        type: 'labels',
                                    }]}
                                />
                            )}
                        />
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}

export default withRouter(PublicReservationConfirmation);