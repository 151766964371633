import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    height: 100%;

    .layout-container {
        padding: 3em 0;

        .summary-wrapper {
            display: flex;

            .col-left {
                width: 60%;

                .header-info {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
        
                    .location-headline {
                        color: ${variables.dpc_fontDark};
                        font-size: 1.4em;
                        margin-bottom: .5em;
                        margin-right: 1em;
                        padding-right: 1em;
                        border-right: .01em solid #CCCCCC;
                    }
                    
                    .product-headline {
                        color: ${variables.dpc_fontDark};
                        font-size: 1.4em;
                        margin-bottom: .5em;
                    }
                }
        
                .headline {
                    font-size: 4em;
                    color: ${variables.dpc_fontDark};
                    font-weight: 900;
                    line-height: 90%;
                }
        
                .subheadline {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 2em;
                    padding-top: 2em;
                    border-top: 3px solid ${variables.dpc_borderRed};
        
                    .site-url {
                        color: red;
                        font-weight: 600;
                        font-size: 120%;
                    }
                }
        
                .summary {
                    .summary-user-offer {
                        .summary-user-offer-info {
                            margin-top: 2em;
                             
                            .info-headline {
                                font-weight: 600;
                                font-size: 1.5em;
                            }
        
                            .info {
                                font-weight: 600;
                                margin-bottom: 1.5em;
        
                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .col-right {
                width: 40%;

                .headline {
                    text-align: center;
                    font-weight: 600;
                    font-size: 2.5em;
                }

                .summary-trainer-info {
                    margin-top: 2em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .trainer-avatar-wrapper {
                        width: 20em;
                    }

                    .trainer-name {
                        margin-top: 1em;
                        font-weight: 600;
                    }

                    .trainer-mail {
                        font-weight: 600;
                    }
                    
                    .trainer-bio {
                        margin-top: 1em;
                        font-weight: 600;
                    }
                }
            }
        }

    }

    .summary-events-list {
        margin-top: 3em;

        .admin-paginated-list {
            .list-header {
                margin-bottom: 2em;
                text-align: center;

                .list-title {
                    color: black;
                }

                .admin-paginated-list-filterbar {
                    display: none;
                }
            }
        }
    }

    @media (max-width: ${variables.tabletS}) {
        .layout-container {   
            .summary-wrapper {
                display: flex;
                flex-direction: column;

                .col-left {
                    width: 100%;

                    .summary {
                        text-align: center;
                    }
                }

                .col-right {
                    width: 100%;
                    margin-top: 5em;
                }
            }
        }
    }
`;
